import React, { useState, useEffect, useRef } from 'react';
import './App.css';

import character1 from './images/character-1.gif';
import character2 from './images/character-2.gif';
import logo from './images/type-walking-logo.gif';
import restartButton from './images/restart-button.gif';

const homeRow = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'];

function App() {
  const [randomLetter, setRandomLetter] = useState('a');
  const [score, setScore] = useState(0);
  const [letterIncrement, setLetterIncrement] = useState(0);
  const [time, setTime] = useState(10000);

  const isKeyPressed = useKeyPress(
    randomLetter,
    () => {
      setLetterIncrement(letterIncrement => letterIncrement + 1);
      setScore(score => score + 1);
    },
    () => {
      setScore(score => score - 1);
    }
  );

  useTimeout(
    () => {
      setLetterIncrement(letterIncrement => letterIncrement + 1);
      setScore(score => score - 1);
    },
    time,
    randomLetter
  );

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * 9);
    const randomLetter = homeRow[randomNumber];

    setRandomLetter(randomLetter);

    if (score >= 5 && score < 10) {
      setTime(8000);
    } else if (score >= 10 && score < 15) {
      setTime(6000);
    } else if (score >= 15 && score < 20) {
      setTime(4000);
    } else if (score >= 20 && score < 25) {
      setTime(2000);
    } else if (score >= 25 && score < 30) {
      setTime(1000);
    } else if (score >= 30 && score < 35) {
      setTime(1000);
    } else if (score >= 35 && score < 40) {
      setTime(500);
    } else {
      setTime(10000);
    }
  }, [letterIncrement]);

  return (
    <div className="App">
      <h1>
        <img src={logo} />
      </h1>
      <div>
        <a href="/">
          <img src={restartButton} width="150" />
        </a>
      </div>

      <p>time: {time / 1000} seconds</p>
      <p>Press:</p>
      <p
        style={{
          fontSize: '2rem',
          color: isKeyPressed ? 'red' : 'black',
          margin: 0,
        }}
      >
        {randomLetter.toUpperCase()}
      </p>
      <p>score: {score}</p>
      <div
        style={{
          textAlign: 'left',
          position: 'relative',
          height: '80px',
        }}
      >
        <img
          src={letterIncrement % 2 ? character1 : character2}
          style={{
            width: '50px',
            position: 'absolute',
            left: `${score * 2}%`,
          }}
        />
      </div>

      <div
        style={{
          width: '400px',
          margin: '0 auto',
        }}
      >
        <p>
          The game is to press the letter shown above your score and if you
          press the wrong letter your score will go down, but if you press the
          right letter, your score will go up and the man will keep walking.
        </p>
        <p>
          If you don't press the right letter within the time limit, your score
          will go down.
        </p>
      </div>
    </div>
  );
}

export default App;

// Hook
function useKeyPress(targetKey, successCallback, failCallback) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      successCallback();
    } else {
      failCallback();
    }

    setKeyPressed(false);
  };

  const downHandler = () => {
    setKeyPressed(true);
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]);

  return keyPressed;
}

function useTimeout(callback, delay, randomLetter) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay, randomLetter]);
}
